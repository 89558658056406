import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../services/store/store";
import {Spinner} from "react-bootstrap";
import TimesheetSettings from "./TimesheetSettings";
import {TimesheetSettingsResource} from "../../../services/ApiDomain";
import TimesheetSettingsTable from './TimesheetSettingsTable';


interface Props {

}

const TimesheetSettingsPage = ({}: Props) => {

    const {club, courses} = useSelector((state: RootState) => state.clubs.selectedClub);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedCourseId, setSelectedCourseId] = useState<string>();
    const [timesheetToEdit, setTimesheetToEdit] = useState<TimesheetSettingsResource>();

    const editTimesheetSettings = (settings: TimesheetSettingsResource) => {
        setTimesheetToEdit(settings);
        setShowModal(true);
    }

    if (!club) {
        return <>
            <Spinner animation='border'/>
        </>
    }

    if (club && !club.hasTimesheet) {
        return <p>You need to enable this option in the club settings before editing timesheet settings.</p>
    }

    return (
        <>
            <div className="pageSubHeading">
                TIMESHEET SETTINGS
            </div>
            <p style={{color: '#707070'}}>
                In this section you can update your timesheet settings.
            </p>

            {club && courses && courses.length === 0 && <TimesheetSettingsTable
                heading={'Default'}
                settings={{
                    timesheetInterval: club.timesheetInterval,
                    timesheetStartTime: club.timesheetStartTime,
                    timesheetEndTime: club.timesheetEndTime,
                    paceOfPlayMinutes: club.paceOfPlayMinutes,
                }}
                onEdit={(settings) => {
                    setSelectedCourseId(undefined)
                    editTimesheetSettings(settings)
                }} />}

            {club && courses && courses.map(c => {
                return <>
                    <TimesheetSettingsTable
                        heading={c.name}
                        settings={{
                            timesheetInterval: c.timesheetInterval,
                            timesheetStartTime: c.timesheetStartTime,
                            timesheetEndTime: c.timesheetEndTime,
                            paceOfPlayMinutes: c.paceOfPlayMinutes,
                        }}
                        onEdit={(settings) => {
                            setSelectedCourseId(c.id)
                            editTimesheetSettings(settings)
                        }} />
                    <div style={{
                        marginTop: '1em',
                        borderTop: '1px solid #ccc',
                        paddingTop: '1em'
                    }}/>
                </>
            })}

            {timesheetToEdit && <TimesheetSettings
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                clubId={club.id}
                courseId={selectedCourseId}
                initialInterval={timesheetToEdit.timesheetInterval}
                initialFirstTeeTime={timesheetToEdit.timesheetStartTime}
                initialLastTeeTime={timesheetToEdit.timesheetEndTime}
                initialPaceOfPlay={timesheetToEdit.paceOfPlayMinutes} />}
        </>
    )
}

export default TimesheetSettingsPage;