import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../../ApiDomain"
import {useApi} from "../../useApi"
import {handicaddieAsyncThunk} from "../asyncThunk";

const SliceName = "CurrentUser"

interface State {
    loading: boolean,
    isHandicaddieUser: boolean
    user?: User
}

const initialState: State = {
    loading: false,
    isHandicaddieUser: false
};

export interface LoadUserActionPayload {
    user?: User
}

let slice = createSlice(
    {
        name: SliceName,
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(loadUser.pending, (state) => {
                    state.loading = true
                })
                .addCase(
                    loadUser.fulfilled,
                    (state, action: PayloadAction<LoadUserActionPayload>) => {
                        if (action.payload.user) {
                            state.user = action.payload.user
                            state.isHandicaddieUser = action.payload.user.email.endsWith('@handicaddie.com')
                        }
                        state.loading = false
                    }
                )
        }
    }
);

export const loadUser = handicaddieAsyncThunk(
    "loadUser",
    async (_, thunkAPI) => {
        let user: User = await useApi().getCurrentUser()
        return {
            user: user
        }
    }
);

export const { } = slice.actions;

export default slice.reducer;