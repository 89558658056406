import React from 'react';
import {Button, Table} from "react-bootstrap";
import {TimesheetSettingsResource} from "../../../services/ApiDomain";

interface Props {
    heading: string
    settings: TimesheetSettingsResource
    onEdit: (x: TimesheetSettingsResource) => void
}

const TimesheetSettingsTable = ({heading, settings, onEdit}: Props) => {
    return <>
        <h6>{heading}</h6>

        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Property</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Interval</td>
                <td>{settings.timesheetInterval}</td>
            </tr>
            <tr style={{marginRight: "1em"}}>
                <td>First Tee Time</td>
                <td>{settings.timesheetStartTime}</td>
            </tr>
            <tr>
                <td>Last Tee Time</td>
                <td>{settings.timesheetEndTime}</td>
            </tr>
            <tr>
                <td>Pace of Play</td>
                <td>{`${Math.floor(settings.paceOfPlayMinutes / 60)} hours `}
                    {settings.paceOfPlayMinutes % 60 != 0 ? `${settings.paceOfPlayMinutes % 60} minutes` : ''}</td>
            </tr>
            </tbody>
        </Table>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Button onClick={() => onEdit(settings)}>
                Edit
            </Button>
        </div>
    </>;
}

export default TimesheetSettingsTable;