import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../services/store/store";
import {Button, Spinner} from "react-bootstrap";
import {Table} from "react-bootstrap";
import {DeleteOutline} from "@material-ui/icons";

import './courses_settings.scss';
import {AddCourseModal} from "./AddCourseModal";
import {useDialog} from "../../../hooks/use-dialog";
import {useApi} from "../../../services/useApi";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../services/Utils";
import {loadSelectedClubCourses} from "../../../services/store/reducers/clubs/asyncActions";
import {Course} from "../../../services/ApiDomain";

interface Props {

}

const CoursesSettingsPage = ({}: Props) => {

    const {club, courses} = useSelector((state: RootState) => state.clubs.selectedClub);

    const dialog = useDialog()
    const { deleteClubCourse } = useApi()
    const dispatch = useDispatch<AppDispatch>()

    if (!club) {
        return <>
            <Spinner animation='border'/>
        </>
    }

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this course? You will only be able to delete the course if it has no future bookings.')) {
            try {
                await deleteClubCourse(club.id, id)
                toast.success('Course deleted successfully.')
                dispatch(loadSelectedClubCourses())
            } catch (e) {
                logErrorFromApi(e)
            }
        }
    }

    function buildCourseName(c: Course) {
        let name = c.name
        if (c.address) {
            let sep = " - "
            let tally = 0
            if (c.address.lineOne) {
                name = name + sep + c.address.lineOne
                sep = ", "
                tally += 1
            }
            if (c.address.lineTwo) {
                name = name + sep + c.address.lineTwo
                sep = ", "
                tally += 1
            }
            if (c.address.lineThree && tally < 2) {
                name = name + sep + c.address.lineThree
                sep = ", "
                tally += 1
            }
            if (c.address.lineFour && tally < 2) {
                name = name + sep + c.address.lineFour
                sep = ", "
                tally += 1
            }
            if (c.address.lineFive && tally < 2) {
                name = name + sep + c.address.lineFive
            }
        }

        return <>{name}</>;
    }

    return (
        <>
            <div className="pageSubHeading">
                COURSES
            </div>
            <p style={{color: '#707070'}}>
                In this section you can configure your club to contain multiple courses. You only need to update these settings if you have more than one course.
            </p>
            <Table borderless hover striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {club && courses && courses.length === 0 && <tr>
                        <td>Default Course - Single Course Club</td>
                        <td />
                    </tr>}
                    {club && courses && courses.map(c => {
                        return <tr>
                            <td>{buildCourseName(c)}</td>
                            <td style={{display: "flex", justifyContent: "flex-end"}}>
                                <span className={"coursesTable_editBtn"}
                                      onClick={() => dialog.handleOpen({
                                          clubId: club.id,
                                          courseId: c.id,
                                          addressEnabled: club?.name.startsWith('Caddies for Kids'),
                                          name: c.name,
                                          colourCode: c.colourCode,
                                          address: c.address
                                      })}
                                >
                                    EDIT
                                </span>

                                <DeleteOutline
                                    className={"coursesTable_deleteBtn"}
                                    onClick={() => handleDelete(c.id)}/>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
            <Button onClick={() => dialog.handleOpen({
                clubId: club.id,
                addressEnabled: club?.name.startsWith('Caddies for Kids') })}>+ Add New Course</Button>

            <AddCourseModal
                open={dialog.open}
                onClose={() => dialog.handleClose()}
                itemData={dialog.data} />
        </>
    )
}

export default CoursesSettingsPage;