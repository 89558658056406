import React, {useState} from 'react';
import {Button, Dropdown, Form, Spinner} from "react-bootstrap";
import {darkerHandicaddieGreen, logErrorFromApi} from "../../../services/Utils";
import {SlotType} from "../../../services/ApiDomain";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../services/store/store";
import {useApi} from "../../../services/useApi";
import {toast} from "react-toastify";
import {loadSelectedClubSlotTypes} from "../../../services/store/reducers/clubs/asyncActions";
import ThreeDotMenu from "../../../components/dotmenu/ThreeDotMenu";
import EmojiPickerModal from "../../../pages/EmojiPickerModal";
import AlertModal from "../../../pages/AlertModal";

import './slottypesettingspage.scss'

const defaultSlotType = {
    clubId: "",
    title: "",
}

interface Props {

}

const SlotTypesSettingsPage = ({}: Props) => {


    const [loading, setLoading] = useState(false);
    const [emoji, setEmoji] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [selectedSlotType, setSelectedSlotType] = useState<SlotType>(defaultSlotType);
    const [showSlotTypeModal, setShowSlotTypeModal] = useState(false);
    const [showEmojiPickerModal, setShowEmojiPickerModal] = useState(false);
    const [showDeleteSlotTypeModal, setShowDeleteSlotTypeModal] = useState(false);
    const [selectedSlotTypeId, setSelectedSlotTypeId] = useState<string>();

    const {selectedClub} = useSelector((state: RootState) => state.clubs);
    const {createSlotType, deleteSlotType, updateSlotType} = useApi();
    const dispatch = useDispatch<AppDispatch>()

    const createSlotTypeInClub = () => {
        if (!selectedClub.club || !title) {
            toast.error(!selectedClub ? 'User is not a caddie master in the club.' : 'Please enter a slot type name.');
            return;
        }
        setLoading(true);

        createSlotType(selectedClub.club.id, title, emoji)
            .then(() => {
                toast.success('Slot type created successfully.');
                setEmoji("");
                setTitle("");
                dispatch(loadSelectedClubSlotTypes())
            })
            .catch((error) => {
                logErrorFromApi(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const updateSlotTypeInClub = () => {
        if (!selectedSlotType || !selectedSlotType?.id || !selectedClub) {
            return;
        }
        setLoading(true);

        updateSlotType(selectedSlotType.id, selectedSlotType)
            .then(() => {
                toast.success('Slot type updated successfully.');
                setEmoji("");
                setTitle("");
                setShowSlotTypeModal(false);
                setSelectedSlotType(defaultSlotType);
                dispatch(loadSelectedClubSlotTypes())
            })
            .catch((error) => {
                logErrorFromApi(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const deleteSlotTypeInClub = (id: string | undefined) => {
        if (!id || !selectedClub) {
            return;
        }
        setLoading(true);

        deleteSlotType(id)
            .then(() => {
                toast.success('Slot type deleted successfully.');
                setEmoji("");
                setTitle("");
                setShowDeleteSlotTypeModal(false);
                dispatch(loadSelectedClubSlotTypes())
            })
            .catch((error) => {
                logErrorFromApi(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const showAlertModal = () => {
        setShowSlotTypeModal(true);
    }

    const closeAlertModal = () => {
        setSelectedSlotType(defaultSlotType);
    }

    const handleSlotTypeModalUpdate = () => {
        updateSlotTypeInClub();
    }

    const handleSlotTypeModalClose = () => {
        setShowSlotTypeModal(false);
    }

    const renderSlotType = (slotType: SlotType) => {
        return (
            <div className="slot-type-row">
                {selectedSlotType?.id === slotType.id ?
                    <>
                        <div style={{display: 'flex'}}>
                            <Button
                                variant="light"
                                className="add-icon-button"
                                onClick={() => showEmojiModal()}
                                disabled={loading}>
                                {selectedSlotType?.emoji ? selectedSlotType?.emoji : "Add an icon"}
                            </Button>
                            <Form.Control
                                className="slot-type-name-input"
                                type={"text"}
                                placeholder={"Enter a slot type name"}
                                value={selectedSlotType?.title}
                                onChange={(e) => setSelectedSlotType({...selectedSlotType, title: e.target.value})}
                            />
                        </div>

                        <div>
                            {loading && <Spinner animation='border' className="m-r-10"/>}
                            <Button variant='outline-danger' style={{marginRight: '0.5em'}} onClick={closeAlertModal}
                                    disabled={loading}>
                                Cancel
                            </Button>
                            <Button onClick={() => showAlertModal()} disabled={loading}>UPDATE</Button>
                        </div>
                    </> :
                    <>
                        <div>
                            {slotType.emoji ? slotType.emoji + " " + slotType.title : slotType.title}
                        </div>
                        <div>
                            <ThreeDotMenu>
                                <>
                                    {<Dropdown.Menu popperConfig={{strategy: "fixed"}}>
                                        <Dropdown.Item
                                            onClick={() => setSelectedSlotType(slotType)}>Edit</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => showDeleteSlotTypeWarningModal(slotType.id)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>}
                                </>
                            </ThreeDotMenu>
                        </div>
                    </>
                }
            </div>
        );
    };

    const showEmojiModal = () => {
        setShowEmojiPickerModal(true);
    }

    const handleEmojiPickerModalUpdate = (emoji: string | undefined) => {
        if (emoji) {
            if (selectedSlotType?.id) {
                setSelectedSlotType({...selectedSlotType, emoji: emoji})
            } else {
                setEmoji(emoji);
            }
        }
        handleEmojiPickerModalClose();
    }

    const handleEmojiPickerModalClose = () => {
        setShowEmojiPickerModal(false);
    }

    const showDeleteSlotTypeWarningModal = (id: string | undefined) => {
        if (!id) {
            return;
        }
        setSelectedSlotTypeId(id);
        setShowDeleteSlotTypeModal(true);
    }

    const handleDeleteSlotTypeModalUpdate = () => {
        if (!selectedSlotTypeId) {
            return;
        }
        deleteSlotTypeInClub(selectedSlotTypeId);
    }

    const handleDeleteSlotTypeModalClose = () => {
        setSelectedSlotTypeId("");
        setShowDeleteSlotTypeModal(false);
    }

    return (
        <>
            <div className="pageSubHeading">
                SLOT TYPES
            </div>
            <p style={{color: '#707070'}}>As a caddiemaster you can create custom caddie slot types to fit your
                caddie programme.</p>

            <div className="caddie-slot-name-text">
            </div>

            <div className="add-slot-container">
                <div style={{display: 'flex'}}>
                    <Button variant="light"
                            className="add-icon-button"
                            onClick={() => showEmojiModal()}
                            disabled={loading}>
                        {emoji ? emoji : "Add an icon"}
                    </Button>
                    <Form.Control
                        className="slot-type-name-input"
                        type={"text"}
                        placeholder={"Enter a slot type name"}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>

                <div className="m-l-10">
                    <Button style={{backgroundColor: darkerHandicaddieGreen, borderRadius: 5}}
                            onClick={createSlotTypeInClub}>
                        ADD SLOT
                    </Button>
                </div>
            </div>

            <div>
                {selectedClub.slotTypes.length > 0 ?
                    selectedClub.slotTypes.map((slotType: SlotType) => (
                        renderSlotType(slotType)
                    )) :
                    <div
                        style={{padding: 10, textAlign: 'center', backgroundColor: '#ffffff', borderRadius: "5px"}}>
                        There is no data to display
                    </div>}
            </div>


            <EmojiPickerModal
                selectedEmojiValue={emoji || selectedSlotType?.emoji}
                showEmojiPickerModal={showEmojiPickerModal}
                handleEmojiPickerModalUpdate={handleEmojiPickerModalUpdate}
                handleEmojiPickerModalClose={handleEmojiPickerModalClose}
            />
            <AlertModal
                showAlertModal={showSlotTypeModal}
                titleText={"Update Slot Type"}
                bodyText={"Are you sure you want to update the slot type? It will affect all past and future bookings with this slot type."}
                buttonText={"Update"}
                handleAlertModalUpdate={handleSlotTypeModalUpdate}
                handleAlertModalClose={handleSlotTypeModalClose}
            />
            <AlertModal
                showAlertModal={showDeleteSlotTypeModal}
                titleText={"Delete Slot Type"}
                bodyText={"Are you sure you want to delete the slot type? It will affect all past and future bookings with this slot type."}
                buttonText={"Delete"}
                handleAlertModalUpdate={handleDeleteSlotTypeModalUpdate}
                handleAlertModalClose={handleDeleteSlotTypeModalClose}
            />
        </>
    )
}

export default SlotTypesSettingsPage;