import React from "react";
import {CourseCreateForm} from "./CourseCreateForm";
import {Modal} from "react-bootstrap";

type Props = {
    open: boolean,
    onClose: () => void,
    itemData?: any;
}

export const AddCourseModal = ({open, onClose, itemData}: Props) => {
    return (
        <Modal
            show={open}
            backdrop="static"
            keyboard={false}
            id="staticBackdrop"
            tabIndex="-1"
            centered
            size="sm"
            contentClassName="">
            <CourseCreateForm onClose={onClose} item={itemData} />
        </Modal>
    );
};


